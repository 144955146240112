import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import {
  headingSizeTheme as HEADING_SIZE,
  bodyCopySizeTheme as BODY_COPY_SIZE,
  iconTheme as ICON_THEME,
  getThemeStyles,
} from 'holded/lib/styles';
import { getCustomTag, hasKey, isDefault } from 'holded/lib/utils';
import { Feature4Section } from 'holded/modules/cms/domain/components/feature';
import { defaultThemeValues, FeatureTheme } from 'holded/modules/cms/ui/sections/feature/FeatureTheme';

import styles from './feature.module.scss';

const HeroIcon = dynamic(() => import('holded/modules/cms/ui/shared/components/HeroIcon'));
const NextImage = dynamic(() => import('holded/modules/cms/ui/shared/components/Image'));

const FEATURE_THEME = FeatureTheme.feature4;
const Feature4 = ({
  id,
  title,
  titlePosition = 'left',
  description,
  features,
  background,
  iconTheme,
  columns,
  customTag,
}: Feature4Section) => {
  const [themeValues, setThemeValues] = useState(defaultThemeValues);

  const theme = isDefault(iconTheme)
    ? hasKey(FEATURE_THEME.icon, background) && FEATURE_THEME.icon[background]
    : ICON_THEME[iconTheme];
  const CustomTag = getCustomTag('h4', customTag?.headingTag);
  const defaultHeadingSize = isDefault(customTag?.headingSize);
  const defaultBodySize = isDefault(customTag?.bodyCopySize);
  const headingSize = `text-h4 md:${HEADING_SIZE[customTag?.headingSize ?? 'default']}`;

  useEffect(() => {
    const themeStyles = getThemeStyles(FEATURE_THEME, background);
    setThemeValues({ ...themeValues, ...themeStyles });
  }, []);

  const titlePositionClass = `text-${titlePosition}`;

  return (
    <>
      {id && (
        <section className={`${themeValues.background}`}>
          <div className="max-w-7xl mx-auto py-10 md:pt-14 md:pb-20  px-4">
            {title && (
              <CustomTag
                className={`${defaultHeadingSize ? 'text-subtitle1' : headingSize} ${
                  themeValues.titleColor
                } tracking-tight md:${titlePositionClass}`}
              >
                {title}
              </CustomTag>
            )}
            {description && (
              <p
                className={`mt-4 max-w-3xl ${
                  defaultBodySize ? 'text-b1' : BODY_COPY_SIZE[customTag?.bodyCopySize ?? 'default']
                } leading-tight ${themeValues.descriptionColor}`}
              >
                {description}
              </p>
            )}
            <div
              className={`grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:grid-cols-${columns} lg:gap-x-8 lg:gap-y-16 mt-10 md:mt-20`}
            >
              {features?.map((feature) => (
                <div key={feature.id}>
                  <div>
                    {feature.image?.data ? (
                      <span className="flex items-center justify-center h-12 w-12 rounded-md">
                        <NextImage media={feature.image.data} classImg={'object-cover'} />
                      </span>
                    ) : (
                      <span
                        className={`flex items-center justify-center h-12 w-12 rounded-md ${
                          theme == 'linear' ? styles[theme] : theme
                        }`}
                      >
                        <HeroIcon icon={feature.icon} outline={true} />
                      </span>
                    )}
                  </div>
                  <div className="mt-6">
                    {feature.title && (
                      <p className={`text-h5 font-medium ${themeValues.titleColor}`}>{feature.title}</p>
                    )}
                    {feature.description && (
                      <p className={`mt-2 text-b3 leading-6 ${themeValues.descriptionColor}`}>{feature.description}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Feature4;
